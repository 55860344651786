.geduHomeMainGrid {
    max-width: 500px;
    padding: 10px;
}

.alert-wrapper{
    --width:40vw;
    --max-width:100vw;
}

.geduHomeJustShowCustomersDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.geduHomeJustShowCustomersDiv > p {
    margin: 0;
    margin-left: 8px;
}

.geduHomeUsersUserRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid gray;
    
    cursor: pointer;
}

.geduHomeUsersUserEmailParagraph {
    flex: 1;
}

.geduHomeIsLoadingGeduUsersDataDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
}