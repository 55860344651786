.credentialsMainGrid {
    max-width: 500px;
    padding: 10px;
}

.credentialsSpinnerDiv {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.credentialsCredDiv {
    margin-top: 20px !important;
    border-bottom: 1px solid gray;
}

.credentialsTitle {
    font-weight: bold;
    font-size: large;
}

.credentialsUsername {
    font-family: 'Courier New', Courier, monospace;
}

.credentialsPassword {
    font-family: 'Courier New', Courier, monospace;
}