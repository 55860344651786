.profileGrid {
    max-width: 500px;
}

.profileMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 50px;
}

.profileNameSurnameParagraph {
    text-align: center;
    font-weight: bold;

    margin-top: 5px;
    margin-bottom: 5px;
}

.profileRoleParagraph {
    text-align: center;
    font-style: italic;

    margin-top: 5px;
    margin-bottom: 5px;
}

.profileEmailParagraph {
    text-align: center;
    color: gray;

    margin-top: 5px;
    margin-bottom: 5px;
}

.profileLogoutDiv {
    margin-top: 50px;
    
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}