.maestroHomeMainGrid {
    max-width: 700px;
}

.maestroHomeUsersWrapUpDiv {
    margin-bottom: 20px;
}

.maestroHomeUsersInfoDiv {
    max-height: 350px;
    overflow-y: scroll;
}

.maestroHomeUserInfoRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 2px;
    margin-bottom: 2px;
}

.maestroHomeUserEmailParagraph {
    flex: 1;
    padding: 5px;
}

.maestroHomeUserInfoParagraph {
    flex: 1;
    padding: 5px;
}

.maestroHomeDataCollectionDiv {
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid gray;
    margin-bottom: 20px;
}

.maestroHomeDataCollectionNameParagraph {
    font-size: large;
    font-weight: bold;
    margin-bottom: 5px;
}

.maestroHomeDataCollectionDescriptionParagraph {
    margin: 0;
    font-style: italic;
}

.maestroHomeDataCollectionKeysValuesDiv {
    margin-left: 30px;
}

.maestroHomeDataCollectionKeyValueDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.maestroHomeAddAbaTesterMainDiv {
    margin-top: 80px;
    margin-bottom: 80px;

    padding: 15px;

    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;
}

.maestroHomeAddAbaTesterTitle {
    padding: 0;
    margin: 0;

    font-size: large;
    text-align: center;

    display: flex;
    flex-direction: column;
}

.maestroHomeAddAbaTesterReferentSelect {
    padding-left: 8px;
}

.maestroHomeCardCenteredButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.maestroHomeAccessesPopover{
    --width:60%;
    --height:40%
}
.maestroHomeuserInfoDiv{
    max-height: 500px;
    overflow-y: scroll;
}